<template>
  <div class="w-full h-full px-5 md:p-2">
    <form id="registration-form" action="/checkout" v-on:submit.prevent="nextStep" v-on:keyup.enter="nextStep">
      <div class="w-full mx-auto my-12 registration-step md:max-w-4xl" v-if="currentStep == 'payment'">
        <h4 class="text-3xl font-bold text-center">Plan & Payment</h4>

        <div class="grid justify-center grid-cols-1 gap-3 justify-items-center" :class="scalingClasses">
          <div
            class="w-full mx-auto mt-3 border-2 shadow-sm cursor-pointer rounded-xl card group hover:border-yellow-400"
            :class="{
              'border-yellow-400 bg-yellow-50': selectedPlanSlug == plan.slug,
            }"
            v-for="plan in plans"
            :key="plan.slug"
            :id="`plan-option-${plan.slug}`"
            v-on:click="selectedPlanSlug = plan.slug">
            <div class="p-8">
              <div>
                <h3 class="text-xl font-semibold leading-8">{{ plan.name }}</h3>
                <div v-if="plan.trial_period">
                  <div>
                    <span
                      class="text-4xl font-bold tracking-tight"
                      :class="{
                        'text-black': selectedPlanSlug == plan.slug,
                        'text-gray-500': selectedPlanSlug != plan.slug,
                      }">
                      {{ usd(plan.trial_fee) }}
                    </span>
                    for {{ plan.trial_period_description }}
                  </div>
                  <div class="my-3">
                    &amp; then {{ usd(plan.recurring_fee) }} / {{ plan.billing_interval_description.replace("1 ", "") }}
                  </div>
                </div>
                <div v-else class="my-3 font-semibold">
                  <span
                    class="text-4xl font-bold tracking-tight"
                    :class="{
                      'text-black': selectedPlanSlug == plan.slug,
                      'text-gray-500': selectedPlanSlug != plan.slug,
                    }">
                    {{ usd(plan.recurring_fee) }}
                  </span>
                  /{{ plan.billing_interval_description.replace("1 ", "") }}
                </div>
              </div>
              <div
                class="py-2 my-5 font-semibold text-center border rounded-md group-hover:border-yellow-400"
                :class="{ 'bg-yellow-400 text-black border-yellow-400': selectedPlanSlug == plan.slug }">
                {{ selectedPlanSlug == plan.slug ? "Selected" : "Choose Plan" }}
              </div>
              <div v-html="plan.description_html"></div>
            </div>
          </div>
        </div>

        <div class="mx-auto my-6 md:max-w-lg">
          <div class="mb-3 text-xl font-semibold leading-8">Credit Card Details</div>
          <div>Credit Card Number</div>
          <div id="cc-number" style="height: 46px" class="w-full p-2 text-lg border border-slate-200"></div>

          <div class="grid grid-cols-1 gap-2 mt-3 md:grid-cols-2">
            <div>
              <div>Expiry</div>
              <input
                id="cc-expiry"
                type="text"
                inputmode="numeric"
                :value="ccExpiry"
                @input="maskCCExpiry($event.target.value)"
                class="w-full text-lg border-slate-200"
                style="font-family: Arial, sans-serif"
                placeholder="MM/YY" />
            </div>

            <div>
              <div>CVV</div>
              <div id="cc-cvv" style="height: 46px" class="w-full p-2 text-lg border border-slate-200"></div>
            </div>
          </div>

          <div class="mt-3 text-sm text-slate-800" v-if="selectedPlanSlug">
            Charges will reflect on your statement as Standard Scores. You will be billed {{ planAmount }} on this day
            each month.
          </div>
        </div>
      </div>

      <div class="w-full mx-auto text-center md:max-w-xl">
        <div v-if="errors" id="error-messages">
          <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
        </div>
      </div>

      <div class="w-full mx-auto md:max-w-xl" v-if="!showSpinner">
        <div v-if="currentStep == 'payment'">
          <button
            id="btn-complete-payment"
            type="button"
            v-bind:disabled="!canCompletePayment"
            v-bind:class="{
              'w-64 mx-auto block text-lg font-semibold p-3': true,
              'bg-amber-400 hover:bg-amber-500': canCompletePayment,
              'bg-slate-200 text-slate-400': !canCompletePayment,
            }"
            v-on:click="getPaymentToken()">
            Complete Payment
          </button>
        </div>
        <div v-else>
          <button
            id="btn-continue"
            type="button"
            class="block w-64 p-3 mx-auto text-lg font-semibold bg-amber-400 hover:bg-amber-500"
            v-on:click="nextStep()">
            Continue
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline-block w-5 h-5 align-sub">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
          </button>
        </div>
      </div>

      <div v-if="showSpinner" class="text-center">
        <Spinner />
      </div>
    </form>
  </div>
</template>

<script>
/* global Spreedly */
import axios from "axios";
import Spinner from "../shared/spinner_svg.vue";
import { inject } from "vue";

export default {
  setup() {
    const spreedlyEnvironmentId = inject("spreedly.environmentId");
    return { spreedlyEnvironmentId };
  },
  components: {
    Spinner,
  },
  data: function () {
    return {
      steps: ["payment", "ssn"],
      currentStep: "payment",
      selectedPlanSlug: null,
      plans: [],
      errors: null,
      showSpinner: false,
      paymentFieldsReady: false,
      ssn: "",
      firstName: "",
      lastName: "",
      ccExpiry: "",
      paymentToken: "",
      paymentCompleted: false,
      spreedlyInitialized: false,
      allowRefocus: false,
    };
  },
  computed: {
    canCompletePayment() {
      return this.paymentFieldsReady && this.selectedPlanSlug;
    },
    planAmount() {
      if (this.selectedPlanSlug) {
        return this.plans[this.selectedPlanSlug];
      } else {
        return "";
      }
    },
    ccExpiryMonth() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      return this.ccExpiry.substring(0, 2);
    },
    ccExpiryYear() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      // This code will need to be adjusted in another 1000 years
      return `20${this.ccExpiry.substring(3)}`;
    },
    scalingClasses(){
      if (this.plans.length == 1) {
        return "max-w-64 mx-auto";
      } else if (this.plans.length == 2) {
        return "max-w-xl mx-auto md:grid-cols-2";
      } else {
        return "md:grid-cols-3";
      }
    },
  },
  methods: {
    maskCCExpiry(string) {
      const upToFourDigits = string.replace(/\D/g, "").substring(0, 4);

      if (upToFourDigits.length < 2) {
        this.ccExpiry = upToFourDigits;
      } else {
        // Add a slash after the 2nd character
        this.ccExpiry = upToFourDigits.substring(0, 2) + "/" + upToFourDigits.substring(2);
      }
    },
    validateCCExpiry() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/) || parseInt(this.ccExpiryYear) < new Date().getFullYear()) {
        this.errors = ["Please enter a valid expiry date"];
        return false;
      }
      return true;
    },
    getPaymentToken() {
      if (!this.validateCCExpiry()) {
        return;
      }

      this.showSpinner = true;
      let data = {
        full_name: `${this.firstName} ${this.lastName}`,
        month: this.ccExpiryMonth,
        year: this.ccExpiryYear,
      };

      Spreedly.tokenizeCreditCard(data);
    },
    submitPaymentToken(token) {
      this.showSpinner = true;

      axios
        .post("/checkout", {
          payment_method_token: token,
          plan: this.selectedPlanSlug,
          ssn: this.ssn, // Sent because enrollment is the next step
        })
        .then((response) => {
          if (response.data.result == "success") {
            // Registration completed, we need to start going through the
            // auth/enrollment process.

            this.paymentCompleted = true;
            window.location = "/ccs-auth";
          } else {
            this.showSpinner = false;
            this.errors = response.data.errors;
          }
        })
        .catch((e) => {
          window.Sentry.captureException(e);
          this.showSpinner = false;
          this.errors = ["An error occurred while attempting to process your request"];
        });
    },
    initializeSpreedly() {
      if (this.paymentFieldsReady) {
        return;
      }
      if (this.spreedlyInitialized) {
        return;
      }

      this.spreedlyInitialized = true;
      this.showSpinner = true;

      Spreedly.init(this.spreedlyEnvironmentId, {
        numberEl: "cc-number",
        cvvEl: "cc-cvv",
      });

      Spreedly.on("ready", () => {
        this.paymentFieldsReady = true;
        this.showSpinner = false;

        Spreedly.setFieldType("number", "text");
        Spreedly.setNumberFormat("prettyFormat");
        Spreedly.setStyle("number", "font-size: 1.125rem; line-height: 1.75rem; width: 100%");
        Spreedly.setStyle("cvv", "font-size: 1.125rem; line-height: 1.75rem;");
      });

      Spreedly.on("errors", (errors) => {
        this.errors = errors.map((e) => e.message);
        this.showSpinner = false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Spreedly.on("paymentMethod", (token, pmData) => {
        this.submitPaymentToken(token);
      });
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const planSlug = urlParams.get("name");

    axios
      .get("/registration/available-plans")
      .then((response) => {
        this.plans = response.data.plans;

        if (planSlug && this.plans.find((plan) => plan.slug == planSlug)) {
          this.selectedPlanSlug = planSlug;
        } else {
          const preselectedSlug = this.plans.find((plan) => plan.preselected)?.slug;
          this.selectedPlanSlug = preselectedSlug || this.plans[0].slug;
        }
      })
      .catch((e) => {
        window.Sentry.captureException(e);
      });

    axios
      .get("/personal-info")
      .then((response) => {
        this.firstName = response.data.first_name;
        this.lastName = response.data.last_name;

        this.initializeSpreedly();
      })
      .catch((e) => {
        window.Sentry.captureException(e);
      });
  },
};
</script>
