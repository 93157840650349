<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="`width: ${progress}%`" :class="barCss">
      <span v-if="text.length > 0">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }, // Percentage
    text: {
      type: String,
      default: ""
    },
    barCss: {
      type: String,
      default: "bg-primary"
    }
  }
}
</script>
