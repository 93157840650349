// The credit report JSON received from the TransUnion API has several quirks &
// inconsistencies that make it really complicated to extract the data. To avoid
// repeating a bunch of logic across various Vue components, this module will
// parse the data into a standardized format.
//
// In general, each field in the result will be a hash with three keys, one for
// each bureau e.g {tuc: null, exp: null, eqf: null}
//
// Dates are always returned as ISO strings - let the Vue code handle display.
import * as Sentry from "@sentry/browser";
import ensureArray from "./ensure_array";
import factoryCodeCategories from "./factor_code_categories";
import moment from "moment";

const bureaus = ["tuc", "exp", "eqf"];
const bureauNameMap = {
  tuc: "TransUnion",
  exp: "Experian",
  eqf: "Equifax",
};
const delinquentPayStatuses = ["Late 30 Days", "Late 60 Days", "Late 90 Days", "Late 120 Days"];
const derogatoryPayStatuses = ["Wage Earner Plan", "Collection", "Repossession", "Collection/Chargeoff"];

// Returns a function that can be used to filter an array of data to find items
// from a specific bureau
function fromBureau(bureauName: string): (item: unknown) => boolean {
  return (item: unknown) => {
    return (
      item?.["Source"]?.["Bureau"]?.["@symbol"] == bureauName.toUpperCase() ||
      item?.["Bureau"]?.["@symbol"] == bureauName.toUpperCase()
    );
  };
}

function nameHashToString(n): string {
  return [n?.["@first"], n?.["@middle"], n?.["@last"], n?.["@suffix"]].filter((x) => !!x).join(" ");
}

// 1) Account numbers that are greater than seven digits long shall have the last four digits
// masked with an asterisk.
// 2) Account numbers that are more than three digits long and less than or equal to seven digits
// long shall have the last two digits masked with an asterisk.
// 3) Account numbers that are less than or equal to three digits long shall not have any digits
// masked.
function maskAccountNumber(accountNumber): string {
  if (accountNumber.length > 7) {
    return accountNumber.slice(0, -4) + "****";
  } else if (accountNumber.length > 3) {
    return accountNumber.slice(0, -2) + "**";
  } else {
    return accountNumber;
  }
}

function parseNames(): object {
  const result = { tuc: "", exp: "", eqf: "" };

  const nameArray = ensureArray(mainNode?.["Borrower"]?.["BorrowerName"]);

  bureaus.forEach((bureauName) => {
    const nameData = nameArray
      .filter(fromBureau(bureauName))
      .find((x) => x?.["NameType"]?.["@description"] == "Primary");
    result[bureauName] = nameHashToString(nameData?.["Name"] || {});
  });

  return result;
}

function parseAliases(): object {
  const result = { tuc: "", exp: "", eqf: "" };

  const nameArray = ensureArray(mainNode?.["Borrower"]?.["BorrowerName"]);

  bureaus.forEach((bureauName) => {
    const aliasDataArray = nameArray
      .filter(fromBureau(bureauName))
      .filter((x) => x?.["NameType"]?.["@description"] != "Primary");
    result[bureauName] = aliasDataArray.map((x) => nameHashToString(x?.["Name"] || {}));
  });

  return result;
}

function parseDatesOfBirth() {
  const result = { tuc: "", exp: "", eqf: "" };

  const dateArray = ensureArray(mainNode?.["Borrower"]?.["Birth"]);

  bureaus.forEach((bureauName) => {
    const nameData = dateArray.find(fromBureau(bureauName));
    result[bureauName] = nameData?.["@date"];
  });

  return result;
}

function addressDataToHash(a) {
  return {
    street: (a?.["@unparsedStreet"] || `${a?.["@houseNumber"]} ${a?.["@streetName"]}`).trim(),
    city: a?.["@city"],
    state: a?.["@stateCode"],
    zip: a?.["@postalCode"],
  }
}

function parseCurrentAddress() {
  const result = { tuc: "", exp: "", eqf: "" };

  const addressArray = ensureArray(mainNode?.["Borrower"]?.["BorrowerAddress"]);

  bureaus.forEach((bureauName) => {
    const addressData = addressArray.find(fromBureau(bureauName));
    result[bureauName] = addressDataToHash(addressData?.["CreditAddress"] || {});
  });

  return result;
}

function parsePreviousAddresses() {
  const result = { tuc: [], exp: [], eqf: [] };

  const addressArray = ensureArray(mainNode?.["Borrower"]?.["PreviousAddress"]);

  bureaus.forEach((bureauName) => {
    const addressDataArray = addressArray.filter(fromBureau(bureauName));
    result[bureauName] = addressDataArray.map((x) => addressDataToHash(x?.["CreditAddress"] || {}));
  });

  return result;
}

function parseEmployers() {
  const result = { tuc: [], exp: [], eqf: [] };

  const employerArray = ensureArray(mainNode?.["Borrower"]?.["Employer"]);

  bureaus.forEach((bureauName) => {
    const employerDataArray = employerArray.filter(fromBureau(bureauName));
    result[bureauName] = employerDataArray.map((x) => {
      return {
        name: x?.["@name"],
        dateReported: x?.["@date"],
      };
    });
  });

  return result;
}

function parseCreditScore(rawReportData: Array<unknown>) {
  const result = { tuc: null, exp: null, eqf: null };

  const tucvs3 = rawReportData.find((item) => item?.["Type"]?.["$"] == "TUCVantageScore3");
  if (tucvs3) {
    result.tuc = tucvs3?.["CreditScoreType"]?.["@riskScore"];
  }

  const expvs = rawReportData.find((item) => item?.["Type"]?.["$"] == "EXPVantageScore");
  if (expvs) {
    result.exp = expvs?.["CreditScoreType"]?.["@riskScore"];
  }

  const eqfvs = rawReportData.find((item) => item?.["Type"]?.["$"] == "EQFVantageScore");
  if (eqfvs) {
    result.eqf = eqfvs?.["CreditScoreType"]?.["@riskScore"];
  }

  return result;
}

// The extractFactorDetails fn takes in an array of data that looks like this:

// [{
//   "$": "explain: An installment account is one with a fixed monthly payment
//   for the life of the loan. Auto loans and student loans are common examples
//   of installment loans. The VantageScore credit score model relies on
//   information in your credit files at the three national credit reporting
//   companies (Equifax, Experian and TransUnion) to generate your score. Your
//   credit file does not contain enough information about your installment
//   accounts. A mix of different types of open and active credit accounts,
//   including installment accounts, can have a positive impact on your credit
//   score."
// }, {
//   "$": "factor: Lack of sufficient relevant installment account information"
// }, {
//   "$": "cando: Maintaining open and active credit accounts in good standing
//   can help improve your credit score."
// }]
//
// and converts it into an obj like this: { explain: "", factor: "", cando: "" }
// containing the respective strings without the prefixes. If there is no prefix
// then the text goes into the factor key

function extractFactorDetails(factorDetailArray: Array<object>, bureauCode: string, sentiment: string): object {
  const result = {
    explain: "",
    factor: "",
    cando: "",
    category: null,
    bureauCode: bureauCode,
    scoreSentiment: sentiment || "neutral"
  };

  if(factoryCodeCategories[bureauCode]){
    result.category = factoryCodeCategories[bureauCode];
  }

  factorDetailArray.forEach((item) => {
    const text = item["$"];

    if (text.includes("explain:")) {
      result.explain = text.replace("explain:", "").trim();
    } else if (text.includes("factor:")) {
      result.factor = text.replace("factor:", "").trim();
    } else if (text.includes("cando:")) {
      result.cando = text.replace("cando:", "").trim();
    } else {
      result.factor = text.trim();
    }
  });

  return result;
}

function parseCreditScoreFactors(rawReportData: Array<unknown>): object {
  const result: { tuc: object[], exp: object[], eqf: object[] } = { tuc: [], exp: [], eqf: [] };

  const tucvs3 = rawReportData.find((item) => item?.["Type"]?.["$"] == "TUCVantageScore3");
  if (tucvs3) {
    result.tuc = ensureArray(tucvs3?.["CreditScoreType"]?.["CreditScoreFactor"]).map((x) =>
      extractFactorDetails(ensureArray(x?.["FactorText"]), x?.["@bureauCode"], x?.["@FactorType"])
    );
  }

  const expvs = rawReportData.find((item) => item?.["Type"]?.["$"] == "EXPVantageScore");
  if (expvs) {
    result.exp = ensureArray(expvs?.["CreditScoreType"]?.["CreditScoreFactor"]).map((x) =>
      extractFactorDetails(ensureArray(x?.["FactorText"]), x?.["@bureauCode"], x?.["@FactorType"])
    );
  }

  const eqfvs = rawReportData.find((item) => item?.["Type"]?.["$"] == "EQFVantageScore");
  if (eqfvs) {
    result.eqf = ensureArray(eqfvs?.["CreditScoreType"]?.["CreditScoreFactor"]).map((x) =>
      extractFactorDetails(ensureArray(x?.["FactorText"]), x?.["@bureauCode"], x?.["@FactorType"])
    );
  }

  return result;
}

// Sort by the date field in descending order. We only take inquiries from the last 2 years
function parseInquiries() {
  const result = { tuc: [], exp: [], eqf: [] };
  const inquiries = ensureArray(mainNode?.["InquiryPartition"]);

  bureaus.forEach((bureauName) => {
    const inquiriesDataArray = inquiries.map((x) => x.Inquiry).filter(fromBureau(bureauName));

    result[bureauName] = inquiriesDataArray
      .map((x) => {
        return {
          name: x?.["@subscriberName"],
          date: x?.["@inquiryDate"],
          industry: x?.["IndustryCode"]?.["@description"],
        };
      })
      .filter((x) => {
        return moment(x.date).isAfter(moment().subtract(2, "years"));
      })
      .sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
  });

  return result;
}

function paymentHistoryDates(tradeline) {
  // Check tradeline.tuc, tradeline.exp and tradeline.eqf for all payment
  // history entries and return an array of unique sorted dates

  const dates = tradeline.tuc.paymentHistory
    .concat(tradeline.exp.paymentHistory, tradeline.eqf.paymentHistory)
    .map((x) => x.monthDate)
    .sort();

  return dates.filter((item, index) => dates.indexOf(item) === index);
}

function parseTradelines() {
  return ensureArray(mainNode?.["TradeLinePartition"]).map((x) => {
    const defaultNode: {
      accountNumber: string | null;
      creditorName: string | null;
      originalCreditorName: string | null;
      accountType: string | null;
      accountDesignator: string | null;
      accountCondition: string | null;
      creditType: string | null;
      loanType: string | null;
      paymentStatus: string | null;
      accountOpenClosed: string | null;
      monthsReviewed: number | null;
      late30Count: number | null;
      late60Count: number | null;
      late90Count: number | null;
      monthlyPaymentAmount: number | null;
      dateOpened: string | null;
      dateClosed: string | null;
      dateReported: string | null;
      dateLastPayment: string | null;
      dateLastActive: string | null;
      balanceAmount: number | null;
      termDurationMonths: number | null;
      highBalanceAmount: number | null;
      highCreditAmount: number | null;
      creditLimitAmount: number | null;
      pastDueAmount: number | null;
      remarks: string | null;
      isDelinquent: boolean;
      isDerogatory: boolean;
      paymentHistory: Array<object>;
      contactPhone: string | null;
      contactAddress: object | null;
    } = {
      accountNumber: null,
      creditorName: null,
      originalCreditorName: null,
      accountType: null,
      accountDesignator: null,
      accountCondition: null,
      creditType: null,
      loanType: null,
      paymentStatus: null,
      accountOpenClosed: null,
      monthsReviewed: null,
      late30Count: null,
      late60Count: null,
      late90Count: null,
      monthlyPaymentAmount: null,
      dateOpened: null,
      dateClosed: null,
      dateReported: null,
      dateLastPayment: null,
      dateLastActive: null,
      balanceAmount: null,
      termDurationMonths: null,
      highBalanceAmount: null,
      highCreditAmount: null,
      creditLimitAmount: null,
      pastDueAmount: null,
      remarks: null,
      isDelinquent: false,
      isDerogatory: false,
      paymentHistory: [],
      contactPhone: null,
      contactAddress: null
    };

    const dataset = {};

    ensureArray(x?.["Tradeline"]).forEach((bureauData) => {
      const node = structuredClone(defaultNode);

      node.accountNumber = maskAccountNumber(bureauData?.["@accountNumber"] || "");
      node.accountCondition = bureauData?.["AccountCondition"]?.["@description"];
      node.creditorName = bureauData?.["@creditorName"];
      node.originalCreditorName = bureauData?.["CollectionTrade"]?.["@originalCreditor"];
      node.accountOpenClosed = bureauData?.["OpenClosed"]?.["@description"];
      node.accountDesignator = bureauData?.["AccountDesignator"]?.["@description"];
      node.dateOpened = bureauData?.["@dateOpened"];
      node.dateClosed = bureauData?.["@dateClosed"];
      node.dateReported = bureauData?.["@dateReported"];
      node.dateLastPayment = bureauData?.["GrantedTrade"]?.["@dateLastPayment"];
      node.dateLastActive = bureauData?.["@dateAccountStatus"];
      node.paymentStatus = bureauData?.["PayStatus"]?.["@description"];
      node.creditType = bureauData?.["GrantedTrade"]?.["CreditType"]?.["@description"] || bureauData?.["CollectionTrade"]?.["creditType"]?.["@description"];
      node.accountType = bureauData?.["GrantedTrade"]?.["AccountType"]?.["@description"];
      node.termDurationMonths = bureauData?.["GrantedTrade"]?.["@termMonths"];
      node.monthsReviewed = parseFloat(bureauData?.["GrantedTrade"]?.["@monthsReviewed"]);
      node.late30Count = parseFloat(bureauData?.["GrantedTrade"]?.["@late30Count"]);
      node.late60Count = parseFloat(bureauData?.["GrantedTrade"]?.["@late60Count"]);
      node.late90Count = parseFloat(bureauData?.["GrantedTrade"]?.["@late90Count"]);
      node.monthlyPaymentAmount = parseFloat(bureauData?.["GrantedTrade"]?.["@monthlyPayment"]);
      node.balanceAmount = parseFloat(bureauData?.["@currentBalance"]);
      node.pastDueAmount = parseFloat(bureauData?.["GrantedTrade"]?.["@amountPastDue"]);
      node.highBalanceAmount = parseFloat(bureauData?.["@highBalance"]);
      node.highCreditAmount = parseFloat(bureauData?.["@highCredit"]);
      node.creditLimitAmount = parseFloat(bureauData?.["GrantedTrade"]?.["CreditLimit"]?.["$"]);
      node.remarks = bureauData?.["Remark"]?.["@customRemark"];
      node.isDelinquent = delinquentPayStatuses.includes(node.paymentStatus || "");
      node.isDerogatory = derogatoryPayStatuses.includes(node.paymentStatus || "");

      const subscriber = ensureArray(mainNode?.["Subscriber"]).find((s) => {
        return s["@name"] == node.creditorName
      }) || {}

      node.contactPhone = subscriber["@telephone"]
      node.contactAddress = addressDataToHash(subscriber["CreditAddress"] || {})

      // In a couple of places we need to show payment history by month, so
      // rather than adding extra code there to only compare the first 7 chars
      // of the string, I've chosen to just add another field "monthDate" that
      // sets the day to 01
      node.paymentHistory = ensureArray(bureauData?.["GrantedTrade"]?.["PayStatusHistory"]?.["MonthlyPayStatus"])
        .map((x) => {
          return {
            date: x["@date"],
            monthDate: `${x["@date"].slice(0, 7)}-01`,
            status: x["@status"],
          };
        })
        .reverse();

      // Drop items from the start of the array if status is blank or just contains whitespace
      while (node.paymentHistory.length > 0 && !node.paymentHistory[0]["status"].trim()) {
        node.paymentHistory.shift();
      }

      const nodeBureauSymbol = bureauData?.["Source"]?.["Bureau"]?.["@symbol"].toLowerCase()

      // Equifax sends "U" as the payment status unless there's an issue - if
      // we're working with Equifax data, we need to convert all "U"s to "C"s
      if (nodeBureauSymbol == "eqf") {
        node.paymentHistory.forEach((x) => {
          if (x["status"] == "U") {
            x["status"] = "C";
          }
        });
      }

      dataset[nodeBureauSymbol] = node;
    });

    dataset["tuc"] ||= defaultNode;
    dataset["exp"] ||= defaultNode;
    dataset["eqf"] ||= defaultNode;

    dataset["paymentHistoryDates"] = paymentHistoryDates(dataset);

    return dataset;
  });
}

function parseReportDates() {
  const result = { tuc: "", exp: "", eqf: "" };

  const sourceNode = ensureArray(mainNode?.["Sources"]?.["Source"]);

  const tucData = sourceNode.find(fromBureau("tuc"));
  result.tuc = tucData?.["InquiryDate"]?.["$"] || "";

  const expData = sourceNode.find(fromBureau("exp"));
  result.exp = expData?.["InquiryDate"]?.["$"] || "";

  const eqfData = sourceNode.find(fromBureau("eqf"));
  result.eqf = eqfData?.["InquiryDate"]?.["$"] || "";

  return result;
}

// Although this method is supposed to parse ALL public records, currently we're
// only focusing on bankruptcies.
function parsePublicRecords(){
  const publicRecords = ensureArray(mainNode?.["PulblicRecordPartition"]).map((x) => ensureArray(x.PublicRecord));

  return publicRecords.filter((x) => x[0]?.["Classification"]?.["@description"] == "Bankruptcy").map((publicRecordDataArray) => {
    const result = { tuc: [], exp: [], eqf: [], type: null };
    bureaus.forEach((bureauName) => {
      const dataArray = publicRecordDataArray.find(fromBureau(bureauName));

      result.type ||= dataArray?.["Classification"]?.["@description"];

      result[bureauName] = {
        type: dataArray?.["Type"]?.["@description"],
        status: dataArray?.["Status"]?.["@description"],
        dateFiled: dataArray?.["@dateFiled"],
        reference: dataArray?.["@referenceNumber"],
        closingDate: "", // TODO: Figure out how to extract this
        assetAmount: parseFloat(dataArray?.["Bankruptcy"]?.["@assetAmount"]),
        liabilityAmount: parseFloat(dataArray?.["Bankruptcy"]?.["@liabilityAmount"]),
        exemptAmount: parseFloat(dataArray?.["Bankruptcy"]?.["@exemptAmount"]),
        court: dataArray?.["@courtName"],
      }
    });

    return result
  })

}

function parseSummary() {
  const tradelineSummaryData = mainNode?.["Summary"]?.["TradelineSummary"];

  const fieldNameMap = {
    totalAccounts: "@TotalAccounts",
    openAccounts: "@OpenAccounts",
    closedAccounts: "@CloseAccounts",
    delinquentAccounts: "@DelinquentAccounts",
    derogatoryAccounts: "@DerogatoryAccounts",
    totalBalance: "@TotalBalances",
    totalMonthlyPayments: "@TotalMonthlyPayments",
  };

  const tradelineSummary = {};

  Object.keys(bureauNameMap).forEach((bureauName) => {
    tradelineSummary[bureauName] = {};

    // Converts the source data that looks like
    // "TradeLineSummary": { "TransUnion": { "@TotalAccounts": "1" } }
    // to
    // tradelineSummary: { tuc: { totalAccounts: 1 } }

    Object.keys(fieldNameMap).forEach((fieldName) => {
      tradelineSummary[bureauName][fieldName] =
        parseInt(tradelineSummaryData?.[bureauNameMap[bureauName]]?.[fieldNameMap[fieldName]]) || 0;
    });
  });

  tradelineSummary["all"] = {};
  // If the "Merge" section isn't present, it usually means the report only has
  // TUI data, so we should copy that into the "all" section. Otherwise, we
  // extract it from the "Merge" section
  if (tradelineSummaryData?.["Merge"]) {
    Object.keys(fieldNameMap).forEach((fieldName) => {
      tradelineSummary["all"][fieldName] = parseInt(tradelineSummaryData?.["Merge"]?.[fieldNameMap[fieldName]]) || 0;
    });
  } else {
    tradelineSummary["all"] = tradelineSummary["tuc"];
  }

  return {
    tradelineSummary,
  };
}

// Multiple methods in this module need to find the main object in the JSON data
// that contains the majority of the credit report data.
let mainNode: object = {};
function setMainNode(rawReportData: Array<object>) {
  if (Object.keys(mainNode).length > 0) {
    return;
  }

  const x = rawReportData.find((item) => item?.["Type"]?.["$"] == "MergeCreditReports");
  if (x) {
    mainNode = x["TrueLinkCreditReportType"];
  }
}

export default {
  parse(rawReportData: Array<object>) {
    console.log(rawReportData); // TODO: Remove this
    try {
      setMainNode(rawReportData);
      const result = {
        singleBureauReport: false,
        hasBankruptcy: false,
        creditScore: parseCreditScore(rawReportData),
        creditScoreFactors: parseCreditScoreFactors(rawReportData),
        summary: parseSummary(),
        reportDate: parseReportDates(),
        borrower: {
          name: parseNames(),
          aliases: parseAliases(),
          dateOfBirth: parseDatesOfBirth(),
          currentAddress: parseCurrentAddress(),
          previousAddresses: parsePreviousAddresses(),
          employers: parseEmployers(),
        },
        inquiries: parseInquiries(),
        tradelines: parseTradelines(),
        publicRecords: parsePublicRecords(),
      };

      result.singleBureauReport = !!result.creditScore.tuc && !result.creditScore.exp && !result.creditScore.eqf;
      return result;
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return null;
    }
  },
};
