<template>
  <div>
    <h4>Subscription: {{ store.profile.planName }}</h4>

    <div class="p-sm my-md border-l-4 border-green-500">
      <div class="comment-s">Basic Cost</div>
      <div class="text-lg mt-sm">{{ recurringFeeDescription }}</div>
    </div>

    <div class="flex my-md gap-md">
      <div>
        <div class="comment-s">Next Charge</div>
        <div class="text-lg mt-sm">{{ formatDate(store.profile.nextBillingOn, "MMM D, YYYY") }}</div>
      </div>
      <div>
        <div class="comment-s">Charge Amount</div>
        <div class="text-lg mt-sm">${{ store.profile.billingDetails.amount }}</div>
      </div>
    </div>

    <div class="my-md">
      <a href="#/purchase-credit-report" class="btn btn-primary">Purchase Credit Report</a>
    </div>
  </div>
</template>
<script>
import { store } from "../store";

export default {
  data() {
    return {
      store,
    };
  },
  computed: {
    recurringFeeDescription() {
      if (store.profile.billingDetails.interval == 1) {
        return `$${store.profile.billingDetails.amount} / ${store.profile.billingDetails.intervalUnit}`
      } else {
        return `$${store.profile.billingDetails.amount} / ${store.profile.billingDetails.interval} ${store.profile.billingDetails.intervalUnit}s`
      }
    },
  },
}
</script>
