import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "progress-bar-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["progress-bar", $props.barCss]),
      style: _normalizeStyle(`width: ${$props.progress}%`)
    }, [
      ($props.text.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.text), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */)
  ]))
}